import { MVEnv } from '@mfe/services/env-files';

const env: MVEnv = {
  env: 'PROD',
  myViasatUrl: 'https://my.viasat.com',
  debug: {
    auth: false,
    dev: false,
    showAnalytics: false,
    skipLogin: false,
  },
  microfrontends: {
    tsUsage: {
      url: 'https://my-viasat.ts-usage.prod.icat.viasat.io/Usage',
    },
    manageBilling: {
      url: 'https://my-viasat.ts-usage.prod.icat.viasat.io/ManageBilling',
    },
    overview: {
      url: 'https://my-viasat.ts-usage.prod.icat.viasat.io/Overview',
    },
    auth: {
      url: 'https://my-viasat.ts-usage.prod.icat.viasat.io/auth',
    },
    profile: {
      url: 'https://my-viasat.ts-usage.prod.icat.viasat.io/Profile',
    },
  },
  backEndUrls: {
    backEndUrl: 'https://my.viasat.com/api/graphql',
    androidBackEndUrl: 'https://my.viasat.com/api/graphql',
  },
  pixel: {
    namespace: 'MyViasat',
    cookieDomain: 'viasat.com',
  },
  adobeTargetScript:
    'https://assets.adobedtm.com/138ddf5a8359/0af984a4bffc/launch-760fd82c6b06.min.js',
};

export default env;
